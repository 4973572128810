.call-button-container {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
    /* overflow: hidden; */
    flex-direction: row-reverse;
    transform: translateX(80%);
}

.call-button-container.active {
    transform: translateX(5%);
    flex-direction: row;
}

.call-button-container>div {
    display: flex;
    flex-direction: column;
    padding: 8px 16px;
    gap: 8px;
    height: 53px;
    background-color: var(--dark-blue-95);
    border-radius: 10px;
    opacity: 0;
    transition: 0.3s ease-in-out;
    z-index: -1;
    transform: translateX(160%);
    transition: 0.3s ease-in-out;
    box-shadow: 0px 2px 4px 0px #00000015;
}

.call-button-container>div.active {
    opacity: 1;
    /* z-index: 1; */
    transform: translateX(0%);
}

.call-button-container>div>p:first-child {
    font-size: 13px;
    text-align: left;
    font-weight: 400;
    color: var(--dark-blue-40);
    line-height: 1.1;
    letter-spacing: 2px;
}

.call-button-container>div>p:last-child {
    font-size: 15px;
    text-align: left;
    font-weight: 600;
    color: var(--dark-blue-40);
    line-height: 1.1;
    letter-spacing: 2px;
}

.call-button-container>button {
    background-color: white;
    height: 53px;
    width: 53px;
    border: none;
    border-radius: 10px;
    cursor: pointer;
    box-shadow: 0px 2px 4px 0px #00000015;
    transition: 0.3s ease-in-out;
}

.call-button-container>button:hover {
    background-color: var(--dark-blue-95);
}

.call-button-container>button>svg>g>path {
    fill: var(--dark-blue-60);
    transition: 0.3s ease-in-out;
}

.call-button-container>button:hover svg>g>path {
    fill: var(--dark-blue-60);
}

.call-button-container>button.active {
    background-color: var(--dark-blue-95);
}

.call-button-container>button.active>svg>g>path {
    fill: var(--dark-blue-40);
}

@media (min-width : 0px) and (max-width : 570px) {
    .call-button-container>button {
        height: 40px;
        width: 40px;
    }

    .call-button-container.active {
        transform: translateX(0%);
        flex-direction: row;
    }

    .call-button-container>button>svg {
        height: 18px;
        width: 18px;
    }

    .call-button-container>div {
        height: 40px;
        gap: 3px;
        padding: 5px 20px;
    }

    .call-button-container>div>p:first-child {
        font-size: 10px;
    }

    .call-button-container>div>p:last-child {
        font-size: 13px;
    }
}