.carousel {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    border-radius: 0px 0px 40px 40px;
    overflow: hidden;
    position: relative;
}

.carousel>.rate {
    position: absolute;
    top: 50px;
    left: 0px;
}

.carousel>.rate.google {
    left: 130px;
}

.carousel>.rate.trustpilot {
    left: 350px;
}

.carousel>div {
    width: 50%;
    height: 100%;
    position: relative;
}

.carousel>.imgs {
    overflow: hidden;
    width: 70%;
    border-radius: 0px 0px 0px 40px;
}

.inactive {
    display: none;
}

.carousel>.imgs>.vector {
    position: absolute;
    left: 0;
    bottom: 0;
    border-radius: 0px 0px 0px 40px;
    animation: animate 1s ease 0s 1 normal forwards;
    width: 897px;
    height: 577px;
}

@keyframes animate {
    0% {
        width: 500px;
        height: 350px;
        transform: translate3d(-750px, 750px, -800px);
    }

    100% {
        transform: translate3d(0px, 0px, 0px);
    }
}

.carousel>.imgs>.hero-img0 {
    position: absolute;
    left: 51px;
    z-index: 1;
    animation: animate0 5s infinite;
    animation-delay: 0s;
}

.carousel>.imgs>.hero-img1 {
    position: absolute;
    top: 87px;
    left: 250px;
    z-index: 1;
    animation: animate1 5s infinite;
    animation-delay: 0s;
}

.carousel>.imgs>.hero-img1>.img {
    position: absolute;
    top: 0;
}

.carousel>.imgs>.hero-img1>.img1 {
    opacity: 1;
    animation: imgAnimation1 1s ease 2.5s 1 normal forwards;
}

.carousel>.imgs>.hero-img1>.img2 {
    position: absolute;
    top: 0;
    width: 710px;
    height: 711px;
    opacity: 0;
    left: -62px;
    animation: imgAnimation2 1s ease 2.5s 1 normal forwards;
}

@keyframes imgAnimation1 {
    0% {
        opacity: 1;
    }

    50% {
        opacity: 0.8;
    }

    100% {
        opacity: 0;
    }
}

@keyframes imgAnimation2 {
    0% {
        opacity: 0;
    }

    50% {
        opacity: 0.8;
    }

    100% {
        opacity: 1;
    }
}

.carousel>.imgs>.hero-img2 {
    position: absolute;
    top: 155px;
    left: 250px;
    z-index: 1;
    animation: animate2 5s infinite;
    animation-delay: 0s;
}

.carousel>.imgs>.hero-img2>.img {
    position: absolute;
    top: 0;
}

.carousel>.imgs>.hero-img2>.img1 {
    opacity: 1;
    animation: imgAnimation1 1s ease 2.5s 1 normal forwards;
}

.carousel>.imgs>.hero-img2>.icon1 {
    position: absolute;
    top: 150px;
    opacity: 1;
    animation: imgAnimation1 1s ease 2.5s 1 normal forwards;
    z-index: -1;
}

.carousel>.imgs>.hero-img2>.img2 {
    position: absolute;
    top: 23px;
    opacity: 0;
    left: -11px;
    animation: imgAnimation2 1s ease 2.5s 1 normal forwards;
}

.carousel>.imgs>.hero-img2>.icon2 {
    position: absolute;
    top: 100px;
    opacity: 0;
    left: -11px;
    animation: imgAnimation2 1s ease 2.5s 1 normal forwards;
    z-index: -1;
}

@keyframes imgAnimation1 {
    0% {
        opacity: 1;
    }

    50% {
        opacity: 0.3;
    }

    100% {
        opacity: 0;
    }
}

@keyframes imgAnimation2 {
    0% {
        opacity: 0;
    }

    50% {
        opacity: 0.9;
    }

    100% {
        opacity: 1;
    }
}

@keyframes animate0 {
    0% {
        transform: rotateZ(0deg) translate3d(-850px, 850px, -850px);
    }

    25% {
        transform: rotateZ(-3deg) translate3d(0px, 0px, 0px);

    }

    50% {
        transform: rotateZ(0deg) translate3d(0px, 0px, 0px);
    }

    75% {
        transform: rotateZ(-3deg) translate3d(0px, 0px, 0px);
    }

    100% {
        transform: rotateZ(0deg) translate3d(-850px, 850px, -850px);
    }
}

@keyframes animate1 {
    0% {
        transform: translate3d(-850px, 850px, -850px);
    }

    25% {
        transform: translate3d(0px, 0px, 0px);

    }

    50% {
        transform: translate3d(0px, 0px, 0px);
    }

    75% {
        transform: translate3d(0px, 0px, 0px);
    }

    100% {
        transform: translate3d(-850px, 850px, -850px);
    }
}

@keyframes animate2 {
    0% {
        transform: translate3d(-850px, 850px, -850px);
    }

    25% {
        transform: translate3d(0px, 0px, 0px);

    }

    50% {
        transform: translate3d(0px, 0px, 0px);
    }

    75% {
        transform: translate3d(0px, 0px, 0px);
    }

    100% {
        transform: translate3d(-850px, 850px, -850px);
    }
}

.carousel>.content-container {
    /* width: 30%; */
    padding: 211px 158px 210px 0px;
    animation: contentCAnim 1s ease 0s 1 normal forwards;
}

.content-container>.content {
    width: 100%;
    display: none;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    gap: 52px;
    animation: contentAnim 1s ease 0s 1 normal forwards;
    position: relative;
}

.content-container>.content.active {
    display: flex;
}

.content-container>.content>h1 {
    color: white;
    text-align: left;
    width: 670px;
    font-weight: 600;
}

.content-container>.content>h1>span {
    color: var(--dark-blue-20);
}

.content-container>.content>ul {
    display: flex;
    flex-direction: column;
    gap: 25px;
    align-items: flex-start;
    list-style: none;
}

.content-container>.content>ul>li {
    color: var(--dark-blue-30);
    font-size: 20px;
    font-weight: 400;
    text-align: left;
    display: flex;
    align-items: center;
    gap: 8px;
    /* list-style-image: url(/src/assets/list.svg); */

}


.content-container>.content>.cta {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 8px;
    width: 216px;
    padding: 20px;
    background-color: var(--blue-light-40);
    border-radius: 16px;
    font-size: 20px;
    font-weight: 600;
    position: relative;
    transition: 0.3s ease-in-out;
}

.content-container>.content>.cta>a {
    display: flex;
    color: var(--blue-light-95);
    width: 100%;
    height: 100%;
    text-decoration: none;
}

.content-container>.content>.cta>a>.arrow {
    position: absolute;
    top: 42%;
    right: 10%;
    opacity: 1;
    transition: 0.3s ease-in-out;
}

.content-container>.content>.cta>a>.arrow1 {
    position: absolute;
    top: 42%;
    right: 5%;
    opacity: 0;
    transition: 0.3s ease-in-out;
}

.content-container>.content>.cta:hover {
    background-color: var(--blue-normal-10);
}

.content-container>.content>.cta:hover a>.arrow {
    opacity: 0;
}

.content-container>.content>.cta:hover a>.arrow1 {
    opacity: 1;
}

@keyframes contentAnim {
    0% {
        /* transform: translateY(500px); */
        opacity: 0;
    }

    50% {
        /* transform: translateY(0px); */
        opacity: 0.7;
    }

    100% {
        /* transform: translateY(0px); */
        opacity: 1;
    }
}

@keyframes contentCAnim {
    0% {
        transform: translateY(500px);
        /* opacity: 0; */
    }

    100% {
        transform: translateY(0px);
        /* opacity: 1; */
    }
}

@media (min-width : 0px) and (max-width : 570px) {
    .carousel {
        flex-direction: column-reverse;
        border-radius: 0px;
    }

    .carousel>.rate.google {
        left: -20px;
        width: 200px;
        height: 70px;
    }

    .carousel>.rate {
        top: 70px;
    }

    .carousel>.rate.trustpilot {
        left: 100px;
        width: 200px;
        height: 70px;
    }

    .carousel>.content-container {
        padding: 150px 20px 0px 20px;
        width: 100%;
    }

    .content-container>.content {
        gap: 42px;
    }

    .content-container>.content>h1 {
        font-size: 28px;
        width: 100%;
        line-height: 1.1;
    }

    .content-container>.content>ul>li {
        font-size: 14px;
    }

    .content-container>.content>.cta {
        width: 100%;
        justify-content: center;
    }

    .content-container>.content>.cta>a {
        justify-content: center;
        align-items: center;
        gap: 8px;
    }

    .content-container>.content>.cta>a>.arrow {
        position: relative;
        top: 2px;
        right: auto;
    }

    .carousel>.imgs {
        width: 100%;
        border-radius: 0px;
    }

    .carousel>.imgs>.vector {
        width: 100%;
        height: 240px;
        border-radius: 0px;
    }

    .carousel>.imgs>.hero-img0 {
        width: 498px;
        height: 364px;
        bottom: -10px;
        left: -8%;
    }

    .carousel>.imgs>.hero-img0>.img1 {
        width: 100%;
        height: 100%;
    }

    .carousel>.imgs>.hero-img1 {
        width: 290px;
        height: 300px;
        top: auto;
        bottom: 0;
        left: 160px;
    }

    .carousel>.imgs>.hero-img1>.img {
        width: 100%;
        height: 100%;
    }

    .carousel>.imgs>.hero-img1>.img1 {
        left: -110px;
        width: 90%;
    }

    .carousel>.imgs>.hero-img1>.img2 {
        width: 108%;
        left: -135px;
    }

    .carousel>.imgs>.hero-img2 {
        width: 250px;
        height: 300px;
        top: 65px;
    }

    .carousel>.imgs>.hero-img2>.img {
        width: 100%;
        height: 100%;
    }

    .carousel>.imgs>.hero-img2>.img1 {
        left: -200px;
        top: 0px;
    }

    .carousel>.imgs>.hero-img2>.img2 {
        left: -207px;
        top: 0px;
    }

    .carousel>.imgs>.hero-img2>.icon1 {
        height: 225px;
        width: 225px;
        top: 75px;
        left: -187px;
    }

    .carousel>.imgs>.hero-img2>.icon2 {
        height: 238px;
        width: 240px;
        top: 60px;
        left: -203px;
    }
}

@media (min-width : 570px) and (max-width : 1020px) {
    .carousel>.rate.google {
        left: 20px;
        width: 190px;
    }

    .carousel>.rate.trustpilot {
        left: 190px;
        width: 185px;
    }

    .carousel>.imgs>.vector {
        width: 550px;
        height: 375px;
    }

    .carousel>.imgs {
        flex-shrink: 0;
    }

    .carousel>.imgs>.hero-img0 {
        width: 700px;
        height: 500px;
        bottom: -10px;
        left: 6%;
    }

    .carousel>.imgs>.hero-img0>.img1 {
        width: 100%;
        height: 100%;
    }

    .carousel>.imgs>.hero-img1 {
        width: 500px;
        height: 510px;
        top: 105px;
        bottom: 0px;
        left: 225px;
    }

    .carousel>.imgs>.hero-img1>.img1 {
        left: -99px;
        width: 540px;
        height: 600px;
        top: 103px;
    }

    .carousel>.imgs>.hero-img1>.img2 {
        width: 650px;
        left: -155px;
        top: 102px;
        height: 606px;
    }

    .carousel>.imgs>.hero-img2 {
        width: 500px;
        height: 550px;
        top: 176px;
        left: 300px;
    }

    .carousel>.imgs>.hero-img2>.img {
        width: 100%;
        height: 100%;
    }

    .carousel>.imgs>.hero-img2>.img1 {
        left: -180px;
        top: 80px;
    }

    .carousel>.imgs>.hero-img2>.img2 {
        left: -199px;
        top: 69px;
    }

    .carousel>.imgs>.hero-img2>.icon1 {
        height: 390px;
        width: 472px;
        top: 205px;
        left: -187px;
    }

    .carousel>.imgs>.hero-img2>.icon2 {
        height: 500px;
        width: 700px;
        top: 140px;
        left: -310px;
    }
    .content-container>.content>h1 {
        font-size: 38px;
        width: 300px;
        line-height: 1.1;
    }

    .content-container>.content>ul {
        max-width: 350px;
    }
    .content-container>.content>ul>li {
        font-size: 16px;
    }

}

@media (min-width : 1020px) and (max-width : 1300px) {
    .carousel>.rate.google {
        left: 65px;
        width: 211px;
    }

    .carousel>.rate.trustpilot {
        left: 250px;
        width: 200px;
    }

    .carousel>.imgs>.vector {
        width: 700px;
        height: 500px;
    }

    .carousel>.imgs {
        flex-shrink: 0;
    }

    .carousel>.imgs>.hero-img0 {
        width: 800px;
        height: 600px;
        bottom: -10px;
        left: 10%;
    }

    .carousel>.imgs>.hero-img0>.img1 {
        width: 100%;
        height: 100%;
    }

    .carousel>.imgs>.hero-img1 {
        width: 600px;
        height: 610px;
        top: auto;
        bottom: 0;
        left: 225px;
    }

    .carousel>.imgs>.hero-img1>.img1 {
        left: -80px;
        width: 90%;
    }

    .carousel>.imgs>.hero-img1>.img2 {
        width: 114%;
        left: -155px;
        top: -18px;
    }

    .carousel>.imgs>.hero-img2 {
        width: 600px;
        height: 650px;
        top: 150px;
        left: 300px;
    }

    .carousel>.imgs>.hero-img2>.img {
        width: 100%;
        height: 100%;
    }

    .carousel>.imgs>.hero-img2>.img1 {
        left: -180px;
        top: 0px;
    }

    .carousel>.imgs>.hero-img2>.img2 {
        left: -207px;
        top: 0px;
    }

    .carousel>.imgs>.hero-img2>.icon1 {
        height: 500px;
        width: 600px;
        top: 160px;
        left: -187px;
    }

    .carousel>.imgs>.hero-img2>.icon2 {
        height: 500px;
        width: 700px;
        top: 140px;
        left: -262px;
    }

    .carousel>.content-container {
        padding: 150px 20px 0px 20px;
        /* width: 100%; */
        flex-shrink: 0;
    }
    .content-container>.content>h1 {
        font-size: 48px;
        max-width: 350px;
        line-height: 1.1;
    }

    .content-container>.content>ul {
        max-width: 350px;
    }

}