.review {
    min-width: 536px;
    height: 314px;
    border-radius: 0px 300px 0px 0px;
    background-color: var(--blue-light-95);
    position: relative;
    padding: 30px;
    transition: 0.3s ease-in-out;
}

.review>.small-vector {
    position: absolute;
    left: 0px;
    bottom: 0px;
    transition: 0.3s ease-in-out;
}

.review>.small-vector>path {
    transition: 0.3s ease-in-out;
}

.review:hover {
    box-shadow: 0px 5px 5px 1px #0A415C15;
}

.review:hover .small-vector>path {
    fill: var(--blue-light-80);
}

.review>.review-content {
    display: flex;
    flex-direction: column;
    gap: 35px;
}

.review>.review-content>.profile {
    display: flex;
    flex-direction: column;
    gap: 20px;
    align-items: flex-start;
}

.review>.review-content>.profile>img {
    width: 69px;
    height: 69px;
    border-radius: 0px 70px 0px 0px;
    transition: 0.3s ease-in-out;
    border: 3px solid transparent;
}

.review:hover .review-content>.profile>img {
    border: 3px solid var(--blue-light-80);
}

.review>.review-content>.profile>div {
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.review>.review-content>.profile>div>p {
    font-size: 18px;
    color: var(--blue-normal-30);
    font-weight: 600;
}

.review>.review-content>div:last-child {
    text-align: left;
    color: var(--blue-normal-30);
    line-height: 1.5;
}

@media (min-width : 0px) and (max-width : 570px) {
    .review {
        min-width: 100%;
        padding: 18px;
        height: 230px;
        border-radius: 0px 250px 0px 0px;
    }
    
    .review>svg {
        width: 72px;
        height: 47px;
        z-index: 0;
    }
    
    .review>.review-content {
        gap: 12px;
    }
    
    .review>.review-content>.profile {
        gap: 12.5px;
    }
    
    .review>.review-content>.profile>img {
        width: 50px;
        border-radius: 0px 43px 0px 0px;
        height: 50px;
    }

    .review>.review-content>.profile>div {
        gap: 8px;
    }

    .review>.review-content>.profile>div>p {
        font-size: 16px;
    }
    .review>.review-content>.profile>div>svg {
        width: 75px;
        height: 11px;
    }

    .review>.review-content>div:last-child {
        font-size: 14px;
        width: 100%;
        z-index: 1;
    }

    .review:nth-child(2) {
        border-radius: 300px 0px 0px 0px;
        background-color: var(--yellow-normal-60);
    }

    .review:nth-child(2)>.small-vector {
        left: auto;
        right: -1px;
        transform: rotateY(180deg);
    }

    .review:nth-child(2)>.review-content {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
    }

    .review:nth-child(2)>.review-content>.profile>img {
        border-radius: 43px 0px 0px 0px;
        align-self: flex-end;

    }

    .review:nth-child(2)>.review-content>.profile>div {
        align-items: flex-end;
    }

    .review:nth-child(2)>.review-content>.profile>div>svg>path {
        fill: var(--blue-light-60);
    }

    .review:nth-child(2)>.review-content>.profile>div>svg>path:last-child {
        fill: #666666;
    }

    .review:nth-child(2)>.review-content>div:last-child {
        font-size: 14px;
        text-align: right;
    }
}