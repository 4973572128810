.partner {
    display: flex;
    flex-direction: column;
    gap: 80px;
    align-items: flex-start;
    width: 100%;
    background-color: #033030;
    padding: 100px 0px 100px 60px;
}

.partner>.partner-header {
    display: flex;
    flex-direction: column;
    gap: 11px;
    width: 100%;
}

.partner>.partner-header>h4 {
    color: #E7FDFD;
    font-size: 32px;
    text-align: left;
    font-weight: 400;
}

.partner>.partner-header>.separator {
    background: linear-gradient(90deg, #3DF5F5 0%, #2CB1B1 67%, #033030 100%);
    width: 100%;
    height: 6px;
    border-radius: 32px;
}

.partner>.partner-container {
    display: flex;
    flex-direction: column-reverse;
    justify-content: space-between;
    width: 100%;
    align-items: flex-start;
    gap: 66px;
}

.partner>.partner-container>div {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 60px;
}

.partner>.partner-container>div>p {
    font-size: 24px;
    color: #E7FDFD;
    text-align: left;
}

.partner>.partner-container>div>p>span {
    color: #3DF5F5;
    font-weight: bold;
}

.partner>.partner-container>div>button {
    background-color: #9FF9F9;
    padding: 28px 43px;
    border-radius: 16px;
    cursor: pointer;
    transition: 0.3s ease-in-out;
}

.partner>.partner-container>div>button>a {
    color: #033030;
    font-size: 20px;
    text-decoration: none;
    width: 100%;
    height: 100%;
    display: block;
    font-weight: 600;
}

.partner>.partner-container>div>button:hover {
    background-color: #3FF3F3;
    box-shadow: 0px 0px 25px 0px #9FF9F9;
}


@media (min-width : 0px) and (max-width : 570px) {
    .partner {
        padding: 60px 0px 60px 20px;
        gap: 60px;
    }

    .partner>.partner-header>h4 {
        font-size: 24px;
    }
    .partner>.partner-container{
        padding-right: 20px;
    }
    .partner>.partner-container>.logo{
        width: 248px;
        height: 118px;
    }
    .partner>.partner-container>div{
        width: 100%;
    }
    .partner>.partner-container>div>p{
        font-size: 14px;
        text-align: left;
        max-width: 350px;
    }
    .partner>.partner-container>div>button {
        width: 100%;
    }
    .partner>.partner-container>div>button>a {
        font-size: 14px;
    }
}

@media (min-width : 570px) and (max-width : 1300px){
    .partner>.partner-container>img{
        width: 250px;
    }
}