.type-assurance {
    display: flex;
    flex-direction: column;
    gap: 80px;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 100px 276px 0px 276px;
    /* overflow: hidden; */
}

.type-assurance>.title {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 6px;
    font-size: 30px;
    font-weight: 600;
    line-height: 1.1;
    color: var(--dark-blue-50);
}

.type-assurance>.types {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    row-gap: 80px;
}

.type-assurance>.types>.type {
    width: 400px;
}

.type-assurance>.types>.type>.img-type {
    position: relative;
    height: 447px;
    width: 100%;
    display: flex;
    align-items: flex-end;
    transition: 0.3s ease-out;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: flex-end;
}

.type-assurance>.types>.type>.img-type>.bg-img1 {
    position: absolute;
    bottom: 0px;
    left: 0;
    opacity: 1;
    transition: 0.3s ease-out;
    z-index: -1;
    width: 100%;
    height: 447px;
}

.type-assurance>.types>.type>.img-type>.bg-img2 {
    position: absolute;
    bottom: 0px;
    left: 0;
    opacity: 0;
    transition: 0.3s ease-in-out;
    z-index: -1;
    width: 100%;
    height: 447px;
}

.type-assurance>.types>.type>.img-type>img {
    transition: 0.3s ease-in-out;
}

.type-assurance>.types>.type:hover .img-type>.bg-img2 {
    opacity: 1;
}

.type-assurance>.types>.type:hover .img-type>img {
    transform: scale(1.1);
}


.type-assurance>.types>.type>.content-type {
    width: 100%;
    height: 180px;
    display: flex;
    flex-direction: column;
    gap: 12px;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 30px;
    border-radius: 0px 0px 40px 40px;

    box-shadow: 0px 4px 4px 2px #00000015;
}

.type-assurance>.types>.type>.content-type>.title-type {
    font-size: 20px;
    font-weight: 600;
}

.type-assurance>.types>.type>.content-type>.descp-type {
    font-size: 18px;
    font-weight: 300;
    text-align: left;
}

@media (min-width : 0px) and (max-width : 570px) {
    .type-assurance {
        padding: 60px 20px 0px 20px;
        gap: 60px;
        align-items: center;
    }

    .type-assurance>.title {
        font-size: 28px;
        align-self: flex-start;
    }

    .type-assurance>.types {
        flex-direction: column;
        row-gap: 60px;
    }

    .type-assurance>.types>.type {
        width: 334px;
        height: 507px;
    }
    .type-assurance>.types>.type>.img-type{
        height: 375px;
    }
    .type-assurance>.types>.type>.img-type>.bg-img1 {
        height: 375px;
        width: 335px;
    }

    .type-assurance>.types>.type>.img-type>.bg-img2 {
        height: 375px;
        width: 335px;
    }

    .type-assurance>.types>.type>.img-type>img {
        height: 399px;
        width: 320px;

    }

    .type-assurance>.types>.type:nth-child(2)>.img-type>img {
        height: 350px;
        width: 270px;
    }
    .type-assurance>.types>.type:nth-child(3)>.img-type{
        overflow: visible;
    }
    .type-assurance>.types>.type:nth-child(3)>.img-type>img {
        height: 275px;
        object-fit: contain;
        width: 360px;
    }

    .type-assurance>.types>.type:nth-child(4)>.img-type>img {
        width: 300px;
        height: 320px;
    }

    .type-assurance>.types>.type:nth-child(5)>.img-type>img {
        width: 300px;
        height: 300px;
    }

    .type-assurance>.types>.type:nth-child(6)>.img-type>img {
        width: 271px;
        height: 351px;
    }

    .type-assurance>.types>.type>.content-type {
        padding: 20px;
        height: 130px;
    }

    .type-assurance>.types>.type>.content-type>.descp-type {
        font-size: 14px;
        line-height: 1.3;
    }

}

@media (min-width : 570px) and (max-width : 1020px) {
    .type-assurance{
        padding: 80px 20px;
    }
    .type-assurance>.types{
        justify-content: center;
        column-gap: 20px;
    }
}
@media (min-width : 1020px) and (max-width : 1300px) {
    .type-assurance{
        padding: 80px 100px;
    }
    .type-assurance>.types{
        justify-content: center;
        column-gap: 80px;
    }
}