.dropdown {
    position: relative;
    width: 161px;
}

.dropdown-toggle {
    display: flex;
    height: 50px;
    align-items: center;
    justify-content: center;
    border: 2px solid transparent;
    cursor: pointer;
    background-color: transparent;
    border-radius: 8px;
    gap: 10px;
    color: white;
    font-size: 20px;
    font-weight: 500;
    transition: 0.3s ease-in-out;
}

.dropdown>.dropdown-toggle>.caret>path {
    fill: white;
}
.dropdown>.dropdown-toggle>.caret{
    transition: 0.3s ease-in-out;
}
.dropdown>.dropdown-toggle>.caret.Up{
    transform: rotate(180deg);
}
.dropdown>.dropdown-toggle:hover {
    background-color: var(--blue-normal-50);
}
.dropdown>.dropdown-toggle.white {
    background-color: var(--blue-normal-50);
}
.dropdown>.dropdown-toggle.open{
    border-radius: 8px 8px 0px 0px;
    background-color: var(--blue-normal-40);
}

.dropdown>.dropdown-menu {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    z-index: 1000;
    background-color: var(--blue-normal-60);
    border: 2px solid var(--blue-normal-40);
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    list-style-type: none;
    padding: 0;
    border-radius: 0px 0px 8px 8px;
}

.dropdown>.dropdown-menu>li {
    cursor: pointer;
    border-bottom: 2px solid var(--blue-normal-40);
    font-size: 20px;
    transition: 0.3s ease-in-out;
}
.dropdown>.dropdown-menu>li:last-child{
    border-bottom: none;
}
.dropdown>.dropdown-menu>li:hover{
    background-color: var(--blue-normal-30);
}
.dropdown>.dropdown-menu>li>a{
    padding: 15px;
    display: block;
    width: 100%;
    height: 100%;
    color: white;
    text-decoration: none;
}


@media (min-width : 0px) and (max-width : 1020px){
    .dropdown{
        width: 100%;
    }
    .dropdown>.dropdown-toggle {
        background-color: var(--blue-normal-50);
    }
    .dropdown>.dropdown-menu{
        position: relative;
    }
}

@media (min-width : 1020px) and (max-width : 1300px){
    .dropdown>.dropdown-toggle{
        height: 45px;
    }
    .dropdown > .dropdown-menu > li>a{
        padding: 10px
    }
}