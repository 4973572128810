.footer {
    position: relative;
    padding: 100px 138px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    overflow: hidden;
    background: linear-gradient(0deg, var(--dark-blue-20) 0%, var(--blue-normal-30) 100%);
}

@property --grad-color-1 {
    syntax: '<color>';
    initial-value: var(--blue-normal-30);
    inherits: false;
}

@property --grad-color-2 {
    syntax: '<color>';
    initial-value: var(--blue-light-80);
    inherits: false;
}

@property --grad-color-3 {
    syntax: '<color>';
    initial-value: var(--yellow-dark-60);
    inherits: false;
}

.footer>.line {
    width: 7px;
    height: 260px;
    border-radius: 26px;
    position: absolute;
    background-color: white;
    left: 56px;
    top: 170px;
    transition: 0.3s ease-in-out;
    background: linear-gradient(180deg, var(--grad-color-1) 0%, var(--grad-color-2) 46%, var(--grad-color-3) 100%);
    transition: --grad-color-1 1s,
        --grad-color-2 1s,
        --grad-color-3 1s;
}

.footer>.line.frame-1 {
    --grad-color-1: var(--yellow-dark-60);
    --grad-color-2: var(--blue-normal-30);
    --grad-color-3: var(--blue-light-80);
}

.footer>.line.frame-2 {
    --grad-color-1: var(--blue-normal-30);
    --grad-color-2: var(--yellow-dark-60);
    --grad-color-3: var(--blue-light-80);
}

.footer>.line.frame-3 {
    --grad-color-1: var(--blue-light-80);
    --grad-color-2: var(--blue-normal-30);
    --grad-color-3: var(--yellow-dark-60);
}

.footer>.logo {
    padding-top: 123px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    gap: 45px;
}

.footer>.logo>p {
    font-size: 12px;
    color: white;
    font-family: Montserrat;
    letter-spacing: 2px;
}

.footer>.navigation {
    display: flex;
    padding: 80px 20px 20px 0px;
    flex-direction: column;
    align-items: flex-start;
    gap: 18px;
    list-style: none;
}

.footer>.navigation>li {
    font-size: 16px;
    font-family: Montserrat;
    padding: 10px 0px;
    letter-spacing: 2px;
}

.footer>.navigation>li>a {
    color: white;
    text-decoration: none;
    transition: 0.3s ease-in-out;
    line-height: 1.1;
    display: flex;
    flex-direction: column;
    gap: 3px;
}

.footer>.navigation>li>a>.separator {
    width: 100%;
    height: 3px;
    background-color: var(--blue-light-80);
    border-radius: 6px;
    transition: 0.3s ease-in-out;
    opacity: 0;
}

.footer>.navigation>li>a:hover {
    color: var(--blue-light-80);
}

.footer>.navigation>li>a:hover .separator {
    opacity: 1;
}

.footer>.infos {
    display: flex;
    flex-direction: column;
    padding: 80px 20px 20px 0px;
    gap: 18px;
    align-items: flex-start;
}

.footer>.infos>p {
    font-size: 16px;
    font-family: Montserrat;
    color: white;
    line-height: 1.3;
    width: 262px;
    text-align: left;
    padding: 10px 0px;
}

.footer>.social-media {
    display: flex;
    flex-direction: column;
    padding: 80px 20px 20px 0px;
    gap: 25px;
    align-items: flex-start;
}

.footer>.social-media>p {
    font-size: 16px;
    font-family: Montserrat;
    color: white;
    line-height: 1.3;
    display: flex;
    flex-direction: column;
    gap: 2px;
    font-weight: 500;
}

.footer>.social-media>p>.underline {
    width: 100%;
    height: 3px;
    background-color: var(--yellow-dark-90);
    border-radius: 6px;
}

.footer>.social-media>ul {
    display: flex;
    gap: 49px;
    justify-content: flex-start;
    list-style: none;
}

.footer>.social-media>ul>li>a>svg>path {
    transition: 0.3s ease-in-out;
}

.footer>.social-media>ul>li>a:hover svg>path {
    fill: var(--yellow-dark-90);
}

@media (min-width : 0px) and (max-width : 570px) {
    .footer {
        flex-direction: column;
        padding: 60px 20px;
        align-items: flex-start;
    }

    .footer>.line {
        width: 162px;
        height: 4px;
        top: 120px;
        left: 20px;
        background: linear-gradient(90deg, var(--grad-color-1) 0%, var(--grad-color-2) 46%, var(--grad-color-3) 100%);
    }

    .footer>.logo {
        padding-top: 0px;
    }

    .footer>.logo>a>img {
        width: 161px;
        height: 40px;
    }


    .footer>.separator {
        width: 100%;
        height: 4px;
        border-radius: 7px;
        background-color: #D9D9D920;
    }

    .footer>.navigation {
        width: 100%;
        padding: 40px 20px 20px 0px;
    }

    .footer>.navigation>li>a>.separator {
        display: none;
    }

    .footer>.infos {
        width: 100%;
        padding: 20px 20px 20px 0px;
    }

    .footer>.social-media {
        width: 100%;
        padding: 30px 20px 20px 0px;
        gap: 40px;
    }

    .footer>.social-media>ul {
        gap: 20px;
    }
}


@media (min-width : 570px) and (max-width : 1300px) {
    .footer {
        flex-direction: column;
        padding: 60px 20px;
        align-items: flex-start;
    }

    .footer>.line {
        width: 162px;
        height: 4px;
        top: 120px;
        left: 20px;
        background: linear-gradient(90deg, var(--grad-color-1) 0%, var(--grad-color-2) 46%, var(--grad-color-3) 100%);
    }

    .footer>.logo {
        padding-top: 0px;
    }

    .footer>.logo>a>img {
        width: 161px;
        height: 40px;
    }


    .footer>.separator {
        width: 100%;
        height: 4px;
        border-radius: 7px;
        background-color: #D9D9D920;
    }

    .footer>.navigation {
        width: 100%;
        padding: 40px 20px 20px 0px;
    }

    .footer>.navigation>li>a>.separator {
        display: none;
    }

    .footer>.infos {
        width: 100%;
        padding: 20px 20px 20px 0px;
    }

    .footer>.social-media {
        width: 100%;
        padding: 30px 20px 20px 0px;
        gap: 40px;
    }

    .footer>.social-media>ul {
        gap: 20px;
    }
}