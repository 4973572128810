.accordion-container {
    width: 100%;
    display: flex;
    gap: 16px;
    flex-direction: column;
}

.accordion-container>.accordion-header {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    height: 122px;
    width: 100%;
    border-radius: 12px;
}

.accordion-container>.accordion-header>.number {
    height: 122px;
    width: 122px;
    font-size: 40px;
    font-weight: 700;
    align-self: center;
    border-radius: 12px 0px 0px 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: 0.3s ease-in-out;
}

.accordion-container>.accordion-header>.number.dark-b {
    color: var(--dark-blue-20);
    background-color: var(--dark-blue-80);
}

.accordion-container>.accordion-header:hover .number.dark-b {
    color: var(--dark-blue-20);
    background-color: var(--dark-blue-70);
}

.accordion-container>.accordion-header>.number.dark-b.active {
    color: var(--dark-blue-95);
    background-color: var(--dark-blue-60);
}

.accordion-container>.accordion-header>.number.light-b {
    color: var(--blue-light-20);
    background-color: var(--blue-light-80);
}

.accordion-container>.accordion-header:hover .number.light-b {
    color: var(--blue-light-20);
    background-color: var(--blue-normal-70);
}

.accordion-container>.accordion-header>.number.light-b.active {
    color: var(--blue-light-95);
    background-color: var(--blue-light-60);
}

.accordion-container>.accordion-header>.number.normal-y {
    color: var(--yellow-normal-20);
    background-color: var(--yellow-normal-80);
}

.accordion-container>.accordion-header:hover .number.normal-y {
    color: var(--yellow-normal-20);
    background-color: var(--yellow-normal-70);
}

.accordion-container>.accordion-header>.number.normal-y.active {
    color: white;
    background-color: var(--yellow-normal-60);
}

.accordion-container>.accordion-header>.number.dark-y {
    color: var(--yellow-dark-20);
    background-color: var(--yellow-dark-80);
}

.accordion-container>.accordion-header:hover .number.dark-y {
    color: var(--yellow-dark-20);
    background-color: var(--yellow-dark-70);
}

.accordion-container>.accordion-header>.number.dark-y.active {
    color: var(--yellow-dark-95);
    background-color: var(--yellow-dark-60);
}



.accordion-container>.accordion-header>.content {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 100%;
    width: 100%;
    font-size: 20px;
    align-self: center;
    border-radius: 0px 12px 12px 0px;
    padding: 0px 16px;
    transition: 0.3s ease-in-out;
}

.accordion-container>.accordion-header>.content.dark-b {
    color: var(--dark-blue-20);
    background-color: var(--dark-blue-97);
}

.accordion-container>.accordion-header:hover .content.dark-b {
    background-color: var(--dark-blue-90);
}

.accordion-container>.accordion-header>.content.dark-b.active {
    color: var(--dark-blue-20);
    background-color: var(--dark-blue-80);
}


.accordion-container>.accordion-header>.content.light-b {
    color: var(--blue-light-20);
    background-color: var(--blue-light-97);
}

.accordion-container>.accordion-header:hover .content.light-b {
    color: var(--blue-light-20);
    background-color: var(--blue-light-90);
}

.accordion-container>.accordion-header>.content.light-b.active {
    color: var(--blue-light-20);
    background-color: var(--blue-light-80);
}


.accordion-container>.accordion-header>.content.normal-y {
    color: var(--yellow-normal-20);
    background: var(--yellow-normal-97);
}

.accordion-container>.accordion-header:hover .content.normal-y {
    color: var(--yellow-normal-20);
    background: var(--yellow-normal-90);
}

.accordion-container>.accordion-header>.content.normal-y.active {
    color: var(--yellow-normal-20);
    background: linear-gradient(90deg, #FFFCEB 0%, #FFEE99 100%);
}



.accordion-container>.accordion-header>.content.dark-y {
    color: var(--yellow-dark-20);
    background: var(--yellow-dark-97);
}

.accordion-container>.accordion-header:hover .content.dark-y {
    color: var(--yellow-dark-20);
    background: var(--yellow-dark-90);
}

.accordion-container>.accordion-header>.content.dark-y.active {
    color: var(--yellow-dark-20);
    background: linear-gradient(90deg, #FDF8ED 0%, #F5DAA3 100%);
}

.accordion-container>.accordion-body {
    display: flex;
    gap: 16px;
    align-items: center;
    justify-content: flex-start;
    height: 130px;
    padding: 16px;
    border-radius: 12px;
}

.accordion-container>.accordion-body.dark-b {
    background-color: var(--dark-blue-80);
}

.accordion-container>.accordion-body.light-b {
    background-color: var(--blue-light-80);
}

.accordion-container>.accordion-body.normal-y {
    background-color: var(--yellow-normal-80);
}

.accordion-container>.accordion-body.dark-y {
    background-color: var(--yellow-dark-80);
}

.accordion-container>.accordion-body>.line {
    height: 100%;
    width: 4px;
    border-radius: 15px;
    background-color: white;
    box-shadow: 0px 4px 13px 0px #E8EFFC;
}

.accordion-container>.accordion-body>.answer {
    width: 100%;
    font-size: 18px;
    font-weight: 500;
    line-height: 120%;
    letter-spacing: 0.1;
    text-align: left;
}

.accordion-container>.accordion-body>.answer.dark-b {
    color: var(--dark-blue-20);
}

.accordion-container>.accordion-body>.answer.light-b {
    color: var(--blue-light-20);
}

.accordion-container>.accordion-body>.answer.normal-y {
    color: var(--yellow-normal-20);
}

.accordion-container>.accordion-body>.answer.dark-y {
    color: var(--yellow-dark-20);
}




@media (min-width : 0px ) and (max-width : 570px){
    .accordion-container>.accordion-header{
        height: 75px;
    }
    .accordion-container>.accordion-header>.number{
        font-size: 25px;
        height: 75px;
    }
    .accordion-container>.accordion-header>.content{
        font-size: 14px;
        text-align: left;
    }
    .accordion-container>.accordion-body>.answer{
        font-size: 14px;
    }
}

@media (min-width : 570px ) and (max-width : 1300px){
    .accordion-container>.accordion-body{
        padding: 16px;
        height: 130px;
        overflow-y: auto;
    }
    .accordion-container>.accordion-body>.answer{
        font-size: 16px;
        padding: 10px;
    }
}