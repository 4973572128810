.nav-bar-container-inner {
    position: sticky;
    top: 0;
    z-index: 3;
    margin-bottom: -90px;
}

.nav-bar-container {
    display: flex;
    padding: 10px 138px;
    justify-content: space-between;
    height: 90px;
    width: 100%;
    align-items: center;
    top: 0;
    border-radius: 0px;
    background-color: var(--blue-light-70);
}

.nav-bar-container.white-nav-bar {
    border-radius: 0px 0px 40px 40px;
    background-color: white;
    box-shadow: 0px 5px 8px 1px #00000010;
}

.nav-bar-container>.nav-bar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 16px;
}

.nav-bar-container>.nav-bar>li {
    list-style: none;
    padding: 18px 20px;
    animation: myAnim 5s ease 0s 1 normal forwards;
}

@keyframes myAnim {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

.nav-bar-container>.nav-bar>button {
    padding: 0px 20px;
    height: 50px;
    border-radius: 8px;
    transition: 0.3s ease-in-out;
}

.nav-bar-container>.nav-bar>button.contact {
    background-color: white;
}

.nav-bar-container>.nav-bar>button.contact.white {
    background-color: var(--dark-blue-20);
}

.nav-bar-container>.nav-bar>button>a {
    color: var(--dark-blue-20);
    text-decoration: none;
    font-size: 20px;
    font-weight: 500;
    transition: 0.3s ease-in-out;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
}

.nav-bar-container>.nav-bar>button.contact:hover a {
    color: var(--dark-blue-40);
}

.nav-bar-container>.nav-bar>button.contact.white>a {
    color: white;
}

.nav-bar-container>.nav-bar>button.contact.white:hover a {
    color: var(--blue-light-70);
}

.nav-bar-container>.nav-bar>button.wellcomapreBtn {
    background-color: #066060;
}

.nav-bar-container>.nav-bar>button.wellcomapreBtn:hover {
    background-color: #033030;
}

.nav-bar-container>.nav-bar>button.wellcomapreBtn>a {
    color: #9FF9F9;
}

.nav-bar-container>.nav-bar>button.wellcomapreBtn:hover a {
    color: #6EF7F7;
}

.nav-bar-container>.nav-bar>li>a {
    font-size: 20px;
    font-weight: 500;
    line-height: 1.1;
    text-decoration: none;
    color: black;
}

.nav-bar-container>.logo {
    width: 260px;
    height: 63px;
}

.nav-bar-container>.logo>a {
    width: 100%;
    height: 100%;
    display: block;
}

.nav-bar-container>.logo>a>img {
    width: 100%;
    height: 100%;
}

.nav-bar-container>.nav-bar-phone {
    display: none;
}

@media (min-width : 0px) and (max-width : 1020px) {
    .nav-bar-container {
        padding: 10px 20px;
        height: 60px;
        position: relative;
    }

    .nav-bar-container>.nav-bar {
        display: none;
    }

    .nav-bar-container>.logo {
        width: 120px;
        height: 40px;
    }

    .nav-bar-container>.logo>a>img {
        width: 160px;
        height: 40px;
    }

    .nav-bar-container>.nav-bar-phone {
        display: block;
        /* position: relative; */
    }

    .nav-bar-container>.nav-bar-phone>.menu-icon {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        gap: 6px;
        cursor: pointer;
        position: relative;
    }

    .nav-bar-container>.nav-bar-phone>.menu-icon>div {
        height: 3px;
        background-color: white;
        border-radius: 3px;
        transition: 0.3s ease-in-out;
    }

    .nav-bar-container>.nav-bar-phone>.menu-icon>.div1 {
        width: 30px;
    }

    .nav-bar-container>.nav-bar-phone>.menu-icon>.div2 {
        width: 23px;
    }

    .nav-bar-container>.nav-bar-phone>.menu-icon>.div3 {
        width: 16px;
    }

    .nav-bar-container>.nav-bar-phone>.menu-icon.open {
        width: 50px;
        height: 50px;
    }

    .nav-bar-container>.nav-bar-phone>.menu-icon.open>.div1 {
        position: absolute;
        top: 50%;
        transform: rotateZ(45deg);
    }

    .nav-bar-container>.nav-bar-phone>.menu-icon.open>.div3 {
        position: absolute;
        top: 50%;
        transform: rotateZ(-45deg);
        width: 30px;
    }

    .nav-bar-container>.nav-bar-phone>.menu-icon.open>.div2 {
        display: none;
    }

    .nav-bar-container.white-nav-bar {
        border-radius: 0px;
    }

    .nav-bar-container.white-nav-bar>.nav-bar-phone>.menu-icon>div {
        background-color: var(--blue-light-70);
    }

    .nav-bar-container>.nav-bar-phone>.nav-bar-list {
        display: flex;
        position: absolute;
        flex-direction: column;
        right: 5%;
        background-color: white;
        top: 70px;
        width: 90%;
        padding: 12px;
        gap: 16px;
        z-index: 3;
        border-radius: 8px;
        align-self: center;
    }

    .nav-bar-container>.nav-bar-phone>.nav-bar-list>button {
        padding: 0px 20px;
        height: 50px;
        border-radius: 8px;
        transition: 0.3s ease-in-out;
    }

    .nav-bar-container>.nav-bar-phone>.nav-bar-list>button.contact {
        background-color: var(--dark-blue-20);
    }

    .nav-bar-container>.nav-bar-phone>.nav-bar-list>button>a {
        color: var(--dark-blue-20);
        text-decoration: none;
        font-size: 20px;
        font-weight: 500;
        transition: 0.3s ease-in-out;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
    }

    .nav-bar-container>.nav-bar-phone>.nav-bar-list>button.contact>a {
        color: white;
    }

    .nav-bar-container>.nav-bar-phone>.nav-bar-list>button.wellcomapreBtn {
        background-color: #066060;
    }

    .nav-bar-container>.nav-bar-phone>.nav-bar-list>button.wellcomapreBtn>a {
        color: #9FF9F9;
    }
}

@media (min-width : 1020px) and (max-width : 1300px) {
    .nav-bar-container{
        padding: 10px 60px;
    }
    .nav-bar-container>.logo {
        width: 160px;
        height: 40px;
    }
    .nav-bar-container>.nav-bar>button {
        padding: 0px 15px;
        height: 50px;
        border-radius: 8px;
        transition: 0.3s ease-in-out;
    }
    .nav-bar-container>.nav-bar>button>a{
        font-size: 16px;
    }
}