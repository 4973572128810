.reviews {
    display: flex;
    flex-direction: column;
    gap: 80px;
    padding: 100px 138px;
    align-items: flex-start;
    position: relative;
    /* width: 800px; */
    overflow: hidden;
}

.reviews>h3 {
    font-size: 30px;
    font-weight: 400;
    color: var(--dark-blue-50);
}

.reviews>h3>span {
    font-weight: 700;
}

.reviews>.reviews-container {
    display: flex;
    gap: 20px;
    position: relative;
    width: 100%;
    /* justify-content: space-between; */
    overflow-x: auto;
    scrollbar-width: none;
}

.reviews>.reviews-container>.overlay {
    position: absolute;
    top: 0;
    right: -100px;
    width: 200px;
    height: 100%;
    background: linear-gradient(-90deg, var(--blue-light-95) -50%, #ffffff80 80%);
    z-index: 1;
}

@media (min-width : 0px) and (max-width : 570px) {
    .reviews {
        padding: 60px 20px 0px 20px;
        gap: 60px;
    }
    .reviews>h3 {
        font-size: 24px;
        text-align: left;
    }
    .reviews>.reviews-container{
        flex-direction: column;
        gap: 42px;
        height: 690px;
        overflow-y: hidden;
    }
    .reviews>.reviews-container>.overlay{
        display: none;
    }
    
}
@media (min-width : 570px) and (max-width : 1020px) {
    .reviews {
        padding: 0px 20px 80px 20px;
        
    }
    .reviews>.reviews-container{
        gap: 20px;        
    }

}
@media (min-width : 1020px) and (max-width : 1300px) {
    .reviews {
        padding: 0px 100px 100px 100px;
        
    }
    .reviews>.reviews-container{
        gap: 30px;        
    }

}