.message-button-container {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    gap: 8px;
    /* overflow: hidden; */
    flex-direction: row-reverse;
    transform: translateX(87%);
}

.message-button-container.active {
    transform: translateX(2%);
    flex-direction: row;
}

.message-button-container>div {
    display: flex;
    flex-direction: column;
    height: 252px;
    width: 273px;
    background-color: var(--dark-blue-95);
    border-radius: 6.5px;
    opacity: 0;
    transition: 0.3s ease-in-out;
    z-index: -1;
    transform: translateX(160%);
    transition: 0.3s ease-in-out;
    box-shadow: 0px 2px 4px 0px #00000015;
}

.message-button-container>div.active {
    opacity: 1;
    /* z-index: 1; */
    transform: translateX(0%);
}

.message-button-container>div>div {
    display: flex;
    flex-direction: column;
    padding: 10px;
    gap: 20px;
    justify-content: flex-start;
    align-items: flex-start;
    border: 3px solid var(--dark-blue-95);
    border-radius: 6.5px;
    height: 100%;
    background-color: white;
}

.message-button-container>div>div>h5 {
    font-size: 18px;
    line-height: 1.1;
    text-align: left;
    font-weight: 600;
    color: var(--dark-blue-40);
    width: 195px;
}

.message-button-container>div>div>p {
    font-size: 12px;
    font-weight: 400;
    line-height: 1.3;
    color: #666666;
    text-align: left;
}

.message-button-container>div>input {
    width: 100%;
    height: 57px;
    border: 3px solid var(--dark-blue-95);
    border-top: 1px solid var(--dark-blue-95);
    border-radius: 6.5px;
    font-size: 14px;
    background-color: white;
    padding: 18px 10px;
    outline: none;
    transition: 0.3s ease-in-out;
}
.message-button-container>div>input:focus{
    border: 3px solid var(--dark-blue-70);
}

.message-button-container>div>input::placeholder {
    font-size: 14px;
    color: #999999;
    text-transform: capitalize;
}

.message-button-container>button {
    background-color: white;
    height: 53px;
    width: 53px;
    border: none;
    border-radius: 10px;
    cursor: pointer;
    box-shadow: 0px 2px 4px 0px #00000015;
    transition: 0.3s ease-in-out;
}

.message-button-container>button:hover {
    background-color: var(--dark-blue-95);
}

.message-button-container>button.active {
    background-color: var(--dark-blue-95);
}

.message-button-container>button>svg>path {
    fill: var(--dark-blue-60);
    transition: 0.3s ease-in-out;
}

.message-button-container>button:hover svg>path {
    fill: var(--dark-blue-60);
}

.message-button-container>button.active>svg>path {
    fill: var(--dark-blue-40);
}

@media (min-width : 0px) and (max-width : 570px) {
    .message-button-container{
        transform: translateX(85%);
    }
    .message-button-container.active{
        transform: translateX(0%);
    }
    .message-button-container>button {
        height: 40px;
        width: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .message-button-container>button>svg {
        width: 18px;
        height: 16px;
    }

    .message-button-container>div {
        height: 240px;
        width: 215px;
    }

    .message-button-container>div>input {
        height: 40px;
    }
}