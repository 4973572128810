.App {
  text-align: center;
  display: flex;
  flex-direction: column;
  position: relative;
  /* gap: 100px; */
}

.App>.container {
  display: flex;
}

.App>.sticky {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-start;
  gap: 12px;
  padding-right: 38px;
  position: sticky;
  top: 120px;
  z-index: 2;
  width: fit-content;
  align-self: flex-end;
  overflow-x: hidden;
  width: 84px;
  transition: 0.3s ease-in-out;
  height: 320px;
}
.App>.sticky.active{
  width: fit-content;
}
.App>.overlay{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 3;
  height: 100%;
  background: linear-gradient(180deg , #66CCFF60 0%, #05202E60 100%);
}

@media (min-width : 0px) and (max-width : 570px) {
  .App>.container {
    display: flex;
    flex-direction: column;
    padding: 60px 0px 0px 0px;
  }
  .App>.sticky{
    padding-right: 10px;
    width: 50px;
    top: 400px;
  }
  .App>.sticky.active{
    padding-right: 10px;
    width: fit-content;
  }
}