.newsletter {
    display: flex;
    flex-direction: column;
    align-self: center;
    justify-content: center;
    background: linear-gradient(0deg, var(--blue-normal-80) 0%, #ffffff 100%);
    gap: 67px;
    height: 820px;
    width: 100%;
    padding: 49px 79px;
    position: relative;
    overflow: hidden;
}

.newsletter>p {
    font-size: 20px;
    font-weight: 500;
    text-align: center;
    color: var(--blue-light-20);
}

.newsletter>.input-container {
    width: 100%;
    height: 90px;
    border-radius: 14px;
    background-color: white;
    display: flex;
    justify-content: space-between;
    padding: 10px 11px;
    border: 2px solid transparent;
    transition: 0.3s ease-in-out;
}

.newsletter>.input-container>input {
    width: 100%;
    padding: 10px 20px;
    font-size: 20px;
    transition: 0.3s ease-in-out;
    outline: none;
}

.newsletter>.input-container>input::placeholder {
    font-size: 20px;
    color: #ccc;
    transition: 0.3s ease-in-out;
}

.input-container:focus-within {
    border: 2px solid var(--blue-normal-70);
}

.newsletter>.input-container>button {
    padding: 18px 40px;
    font-size: 20px;
    background-color: var(--blue-light-40);
    color: var(--blue-light-95);
    align-self: flex-end;
    border-radius: 10px;
    font-weight: 600;
    transition: 0.3s ease-in-out;
    border: 3px solid transparent;
}

.newsletter>.input-container>button:hover {
    background-color: var(--blue-normal-10);
}

.newsletter>.input-container>button:active {
    background-color: var(--blue-normal-5);
    border: 3px solid var(--blue-normal-60);
    color: var(--blue-light-70);
}




.newsletter>.email {
    position: absolute;
    top: 10px;
    right: 60px;
    animation: emailAnim 6s ease 0s infinite none;
}

@keyframes emailAnim {
    0% {
        transform: translate3d(120px, 250px, 100px) rotateZ(180deg);
        width: 50px;
    }

    50% {
        transform: translate3d(0px, 0px, 0px) rotateZ(0deg);
        width: 271px;
    }

    100% {
        transform: translate3d(120px, 250px, 100px) rotateZ(180deg);
        width: 50px;
    }
}

.newsletter>.porteEnv {
    position: absolute;
    bottom: 0px;
    animation: porteAnim 6s ease 0s infinite none;
    z-index: 1;
}


@keyframes porteAnim {
    0% {
        transform: translateY(300px)
    }

    50% {
        transform: translateY(0px)
    }

    100% {
        transform: translateY(300px)
    }
}

.newsletter>.env1 {
    position: absolute;
    bottom: 105px;
    left: 185px;
    animation: env1Anim 6s ease 0s infinite none;
    z-index: 0;
}

@keyframes env1Anim {
    0% {
        transform: translate3d(-60px, 270px, 100px);
        width: 50px;
    }

    50% {
        transform: translate3d(0px, 0px, 0px);
        width: 253px;
    }

    100% {
        transform: translate3d(-60px, 270px, 100px);
        width: 50px;
    }
}

.newsletter>.env2 {
    position: absolute;
    bottom: 180px;
    left: 250px;
    animation: env2Anim 6s ease 0s infinite none;
    z-index: 0;
}

@keyframes env2Anim {
    0% {
        transform: translate3d(-90px, 300px, 100px);
        width: 50px;
    }

    50% {
        transform: translate3d(0px, 0px, 0px);
        width: 253px;
    }

    100% {
        transform: translate3d(-90px, 300px, 100px);
        width: 50px;
    }
}


@media (min-width : 0px) and (max-width : 570px) {
    .newsletter {
        padding: 0px 20px 49px 20px;
        height: 500px;
    }

    .newsletter>p {
        font-size: 14px;
    }
    .newsletter>.input-container {
        width: 100%;
        height: 60px;
    }
    .newsletter>.input-container>button{
        padding: 0px 15px;
        font-size: 16px;
        height: 40px;
    }

    .newsletter>.email {
        width: 127px;
        height: 142px;
        top: -32px;
        right: 6px;
        animation: none;
    }


    .newsletter>.porteEnv {
        position: absolute;
        width: 108px;
        height: 125px;
        animation: none;
        left: 55px;
    }

    .newsletter>.env1 {
        position: absolute;
        bottom: 81px;
        left: 118px;
        animation: env1Anim 6s ease 0s infinite none;
        z-index: 0;
        width: 141px;
        height: 70px;
    }

    .newsletter>.env2 {
        position: absolute;
        bottom: 148px;
        left: 227px;
        animation: env2Anim 6s ease 0s infinite none;
        z-index: 0;
        width: 88px;
        height: 49px;
    }

    @keyframes env1Anim {
        0% {
            width: 50px;
            transform: translate3d(-50px, 20px, 100px);
        }

        50% {
            transform: translate3d(40px, -30px, 0px);
            width: 150px;
        }

        100% {
            transform: translate3d(-50px, 20px, 100px);
            width: 50px;
        }
    }

    @keyframes env2Anim {
        0% {
            transform: translate3d(-130px, 70px, 100px);
            width: 50px;
        }

        50% {
            transform: translate3d(50px, -20px, 0px);
            width: 100px;
        }

        100% {
            transform: translate3d(-130px, 70px, 100px);
            width: 50px;
        }
    }
}

@media (min-width : 570px) and (max-width : 1300px) {
    .newsletter {
        padding: 80px 20px;
    }

    .newsletter>input {
        height: 60px;
    }

}