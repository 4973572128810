.hero-section>.hero-section-container{
    background-color: var(--blue-light-70);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    height: 880px;
    width: 100%;
    border-radius: 0px 0px 40px 40px;
    padding-top: 90px;
    margin-top: -317px;
    overflow: hidden;
}
.hero-section{
    background-color: var(--blue-light-90);
}

@media (min-width : 0px) and (max-width : 570px) {
    .hero-section>.hero-section-container{
        border-radius: 0px;
        padding-top: 60px;
    }
}
