.benefits{
    position: relative;
    padding: 80px 138px;
    display: flex;
    flex-direction: column;
    gap: 80px;
    justify-content: flex-start;
    /* align-items: flex-start; */
    width: 100%;
    height: 500px;
    background-color: var(--blue-light-90);
    overflow: hidden;
}
.benefits>.icon{
    position: absolute;
    left: 0;
    bottom: 0;
}
.benefits>h3{
    font-size: 30px;
    font-weight: 400;
    text-align: left;
    color: var(--blue-normal-40);
}
.benefits>h3>span{
    font-weight: 700;
}
.benefits>.benefits-container{
    display: flex;
    gap: 16px;
   
    justify-content: center;
}
.benefits>.benefits-container>.separator{
    display: none;
}
.benefits>.benefits-container>.benefit{
    display: flex;
    flex-direction: column;
    gap: 36px;
    align-items: center;
    width: 400px;
    z-index: 1;
}
.benefits>.benefits-container>.benefit>h5{
    color: var(--blue-normal-50);
    font-size: 23px;
    font-weight: 600;
}
.benefits>.benefits-container>.benefit>p{
    width: 290px;
    color: var(--blue-light-20);
    font-size: 18px;
}

@media (min-width : 0px ) and (max-width : 570px) {
    .benefits{
        padding: 60px 20px;
        gap: 60px;
        height: auto;
    }
    .benefits>.icon{
        height: 246px;
        width: 376px;
    }
    .benefits>h3{
        font-size: 22px;
        text-align: center;
    }
    .benefits>.benefits-container{
        flex-direction: column;
         align-items: center;
         gap: 40px;
    }
    .benefits>.benefits-container>.separator{
        display: block;
        height: 3px;
        width: 74px;
        align-self: center;
        background-color: #0088CC30;
    }
}

@media (min-width : 570px) and (max-width : 1020px) {
    .benefits{
        height: auto;
        padding: 80px 20px;
    }
    .benefits>.benefits-container{
        flex-wrap: wrap;
        row-gap: 40px;
    }
}
@media (min-width : 1020px) and (max-width : 1300px) {
    .benefits{
        height: auto;
        padding: 80px 100px;
    }
    .benefits>.benefits-container{
        flex-wrap: wrap;
        row-gap: 40px;
    }
}