.form-container {
    display: flex;
    padding: 100px 138px;
    align-items: center;
    gap: 16px;
}

.form-container>div {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 32px;
    width: 60%;
}

.form-container>div>h3 {
    font-size: 30px;
    font-weight: 400;
    line-height: 1.1;
    text-align: left;
    width: 530px;
}

.form-container>div>p {
    font-size: 16px;
    font-weight: 400;
    text-align: left;
    line-height: 1.1;
    width: 430px;
}

.form-container>form {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 10px;
}

.form-container>form>input,
.form-container>form>textarea#message {
    height: 70px;
    width: 100%;
    background-color: #F0F0F0;
    border-radius: 12px;
    padding: 20px;
    outline: none;
    display: flex;
    align-items: flex-start;
    font-size: 20px;
    transition: 0.3s ease-in-out;
    border: 2px solid transparent;
}

.form-container>form>textarea#message {
    height: 120px;
}

.form-container>form>input::placeholder,
.form-container>form>textarea#message::placeholder {
    font-size: 20px;
    color: #B3B3B3;
    align-self: flex-start;
}

.form-container>form>input:hover,
.form-container>form>textarea#message:hover {
    outline: none;
    box-shadow: 0px 4px 4px 1px #0088CC15;
}

.form-container>form>input:focus,
.form-container>form>textarea#message:focus {
    border: 2px solid var(--blue-normal-70);
}

.form-container>form>.send-btn {
    padding: 20px 40px;
    font-size: 20px;
    background-color: var(--blue-light-40);
    color: var(--blue-light-95);
    align-self: flex-end;
    border-radius: 17px;
    font-weight: 600;
    transition: 0.3s ease-in-out;
    border: 3px solid transparent;
}

.form-container>form>.send-btn:hover {
    background-color: var(--blue-normal-10);
}

.form-container>form>.send-btn:active {
    background-color: var(--blue-normal-5);
    border: 3px solid var(--blue-normal-60);
    color: var(--blue-light-70);
}

.form-container>.alert-success {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 20px;
    width: 650px;
}

.form-container>.alert-success>p {
    color: green;
    font-size: 22px;
    font-weight: 500;
    width: 100%;
    text-align: center;

}

.form-container>.alert-success>button {
    font-size: 20px;
    background-color: #035c3e;
    border-radius: 17px;
    font-weight: 600;
    transition: 0.3s ease-in-out;
    border: 3px solid transparent;
}

.form-container>.alert-success>button:hover {
    background-color: #013121;

}

.form-container>.alert-success>button>a {
    padding: 20px 20px;
    text-decoration: none;
    color: var(--blue-light-95);
    display: block;
    width: 100%;
    height: 100%;
}

.form-container>.alert-danger {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 20px;
    width: 650px;
}

.form-container>.alert-danger>p {
    color: red;
    font-size: 22px;
    font-weight: 500;
    width: 100%;
    text-align: center;
}

.form-container>.alert-danger>button {
    font-size: 20px;
    background-color: #bf1d1d;
    border-radius: 17px;
    font-weight: 600;
    transition: 0.3s ease-in-out;
    border: 3px solid transparent;
    padding: 20px 20px;
    text-decoration: none;
    color: var(--blue-light-95);
}

.form-container>.alert-danger>button:hover {
    background-color: #6d1111;
}

.form-container>div>.questions-mark {
    display: none;
}

@media (min-width : 0px) and (max-width : 570px) {
    .form-container {
        flex-direction: column;
        padding: 60px 20px;
        gap: 60px;
    }

    .form-container>div>.questions-mark {
        display: block;
    }

    .form-container>div {
        width: 100%;
    }

    .form-container>div>h3 {
        width: fit-content;
        font-size: 26px;
    }

    .form-container>div>h3>span {
        color: var(--dark-blue-40);
    }

    .form-container>div>p {
        width: fit-content;
        font-size: 14px;
        line-height: 1.2;
    }

    .form-container>form>input::placeholder,
    .form-container>form>textarea#message::placeholder {
        font-size: 14px;
    }

    .form-container>form>input,
    .form-container>form>textarea#message {
        height: 60px;
    }

    .form-container>form>textarea#message {
        height: 200px;
    }

    .form-container>form>.send-btn {
        width: 100%;
        height: 60px;
        padding: 13px 26px;
        font-weight: 500;
    }

    .form-container>.alert-success,
    .form-container>.alert-danger {
        width: 100%;
    }

    .form-container>.alert-success>button,
    .form-container>.alert-danger>button {
        width: 100%;
    }

    .form-container>.alert-success>button>a,
    .form-container>.alert-danger>button {
        padding: 15px;
    }
}

@media (min-width : 570px) and (max-width : 1300px) {
    .form-container {
        padding: 80px 40px;
        flex-direction: column;
        gap: 40px;
    }
    .form-container>div{
        width: 100%;
    }
    .form-container > div > .questions-mark{
        display: block;
        align-self: center;
    }
    .form-container>div>h3, .form-container>div>p{
        width: 100%;
    }
    .form-container>form{
        gap: 20px;
    }
}