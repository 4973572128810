.faq-container {
    padding: 0px 138px 100px 138px;
    display: flex;
    flex-direction: column;
    gap: 45px;
    align-items: flex-start;
    overflow: hidden;
    justify-content: flex-start;
}

.faq-container>.faq-header {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 4px;
}

.faq-container>.faq-header>h3 {
    font-size: 32px;
    font-weight: 700;
    line-height: 1.1;
    letter-spacing: 3px;
    color: #000;
}

.faq-container>.faq-header>.underline {
    display: flex;
    width: 63px;
    height: 6px;
}

.faq-container>.faq-header>.underline>.dark-b {
    height: 100%;
    width: 100%;
    background-color: var(--dark-blue-80);
}

.faq-container>.faq-header>.underline>.light-b {
    height: 100%;
    width: 100%;
    background-color: var(--blue-light-80);
}

.faq-container>.faq-header>.underline>.normal-y {
    height: 100%;
    width: 100%;
    background-color: var(--yellow-normal-80);
}

.faq-container>.faq-header>.underline>.dark-y {
    height: 100%;
    width: 100%;
    background-color: var(--yellow-dark-80);
}

.faq-container>.faq {
    width: 100%;
    display: flex;
    gap: 16px;
    align-items: flex-start;
}

.faq-container>.faq>.faqs {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 16px;
    flex-grow: 2;
}
.faq-container>.faq>img{
    height: 100%;
    flex-grow: 1;
}

@media (min-width : 0px) and (max-width : 570px) {
    .faq-container {
        padding: 60px 20px 0px 20px;
    }

    .faq-container>.faq>img {
        display: none;
    }
}

@media (min-width : 570px) and (max-width : 1020px) {
    .faq-container>.faq>img {
        width: 70%;
        height: 536px;
        object-fit: cover;
        border-radius: 20px;
        object-position: left;
    }
    .faq-container{
        padding: 0px 20px 80px 20px;
    }
}
@media (min-width : 1020px) and (max-width : 1300px) {
    .faq-container>.faq>img {
        object-position: left;
        width: 70%;
        height: 536px;
        object-fit: cover;
        border-radius: 20px;
    }
}